<template>
  <div>
    <slot name="app-before" />
    <div id="app-before"/>
    <PageNavbar />
    <UContainer>
      <div class="grid grid-cols-12 gap-3 my-8">
        <div class="col-span-12 lg:col-span-3">
          <UCard class="h-full lg:mx-0">
            <div class="w-full flex flex-col items-center h-full">
              <AvatarUpload />
              <UButton
                color="neutral"
                variant="subtle"
                block
                icon="i-heroicons-user-circle"
                class="my-4"
                :to="`/users/${userStore.username}`"
                >{{ LL.my_profile() }}</UButton
              >
              <UNavigationMenu :items="links" class="w-full" orientation="vertical" />
            </div>
          </UCard>
        </div>
        <div class="col-span-12 lg:col-span-9">
          <slot />
        </div>
      </div>
    </UContainer>
    <PageFooter />
    <slot name="app-after" />
    <div id="app-after"/>
  </div>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

const appConfig = useAppConfig();
const userStore = useUserStore();
const { path } = useRoute();

const { LL } = typesafeI18n();
const canonical = appConfig.url + path;

const links = computed(() => [
  {
    id: 1,
    label: LL.value.update_profile(),
    icon: "i-heroicons-pencil-square-20-solid",
    to: "/users/me",
  },
  {
    id: 2,
    label: LL.value.change_password(),
    icon: "i-heroicons-wrench-20-solid",
    to: "/users/me/doi-mat-khau",
  },
  {
    id: 3,
    label: LL.value.upgrade_account(),
    icon: "i-heroicons-rocket-launch-20-solid",
    to: "/nang-cap-tai-khoan",
  },
  {
    id: 4,
    label: LL.value.product_feedback(),
    icon: "i-heroicons-chat-bubble-left-right-20-solid",
    to: "/users/me/feedback",
  },
  {
    id: 4,
    label: LL.value.i_need_support(),
    icon: "i-heroicons-lifebuoy-20-solid",
    to: "https://www.messenger.com/t/284537026717633",
    target: "_blank",
  },
]);

useServerSeoMeta({
  robots: "index,follow",
});

useServerHead({
  link: [
    {
      rel: "canonical",
      href: canonical,
    },
  ],
  script: [
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Person",
        name: appConfig.author.name,
        jobTitle: "Founder",
        sameAs: appConfig.organization.members[1].relatedLinks,
      }),
    },
  ],
});
</script>
