<template>
  <div class="flex gap-2 justify-center">
    <div class="relative">
      <label htmlFor="photo-upload">
        <UAvatar
          size="4xl"
          :src="userStore.imageUrl"
          :alt="`${userStore.firstName} ${userStore.lastName}`"
          loading="lazy"
          :ui="{
            image: 'w-[75px] h-[75px]',
            root: 'w-[75px] h-[75px]',
          }"
        />
        <div
          class="cursor-pointer absolute bottom-0 right-0 p-1 rounded-xl border-neutral-300 border bg-neutral-200 text-neutral-800 z-999 text-xs flex items-center capitalize"
        >
          <UTooltip :text="LL.update_avatar()" :popper="{ placement: 'top' }">
            <UIcon name="i-heroicons-photo" />
          </UTooltip>
        </div>
        <input
          id="photo-upload"
          type="file"
          class="hidden"
          accept="image/png, image/jpg, image/jpeg"
          @change="handleUploadImage"
        />
      </label>
    </div>
    <div>
      <p class="text-xl font-bold line-clamp-1">
        {{ userStore.firstName }} {{ userStore.lastName }}
      </p>
      <p class="text-neutral-400 line-clamp-1 dark:text-neutral-600 max-w-[190px]">
        {{ userStore.username }}
      </p>
      <UBadge color="info" variant="outline">{{ userStore.role }}</UBadge>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from "~/store";
import { typesafeI18n } from "~/locales/i18n-vue";

const userStore = useUserStore();
const { LL } = typesafeI18n();

const handleUploadImage = async (e) => {
  e.preventDefault();
  const reader = new FileReader();
  const file = e.target.files[0];
  const data = new FormData();
  data.append("avatar", file);
  reader.readAsDataURL(file);
  await userStore.updateAvatar(data, LL.value.successfully_updated());
};
</script>
